// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-list-js": () => import("./../../../src/pages/blog-list/[...].js" /* webpackChunkName: "component---src-pages-blog-list-js" */),
  "component---src-pages-home-three-js": () => import("./../../../src/pages/home-three.js" /* webpackChunkName: "component---src-pages-home-three-js" */),
  "component---src-pages-home-two-js": () => import("./../../../src/pages/home-two.js" /* webpackChunkName: "component---src-pages-home-two-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

